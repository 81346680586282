<script>
/* eslint-disable */
</script>
<template>
  <div class="m-2">
    <validation-observer ref="textboxrequired">
      <b-row>
        <b-col>
          <validation-provider
            #default="{ errors }"
            name="Codice AC"
            rules="required"
          >
            <b-form-group label="Codice AC" label-class="font-weight-bolder">
              <b-form-textarea v-model="stringAC" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider #default="{ errors }" name="Codice OE" rules="">
            <b-form-group label="Codice OE" label-class="font-weight-bolder">
              <b-form-textarea v-model="stringOE" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" class="mb-3">
          <b-button variant="primary" @click="convertiInTabelle"
            >Converti in Tabelle</b-button
          >
        </b-col>
      </b-row>
    </validation-observer>
    <div v-if="merge.length > 0" class="my-2">
      <h1>Tabella per HTML Danea</h1>
      <b-button
        variant="secondary"
        @click="copiaTabellaHTML"
      >Copia HTML</b-button>
      <table
        ref="tabelladanea"
        class="table table-hover">
        <tbody>
          <tr>
            <th scope="col" style="text-align: left">Marchio</th>
            <th scope="col">Codice Alternativo</th>
          </tr>
          <tr v-for="(prod, index) in merge" :key="index">
            <td style="font-weight: bold">
              <a
                :href="
                  'https://cirar.it/catalogsearch/result/?q=' + prod.codice
                "
                >{{ prod.fornitore }}</a
              >
            </td>
            <td>&nbsp;{{ prod.codice }}</td>
          </tr>
        </tbody>
        <col width="225" />
        <col width="152" />
      </table>
    </div>
    <div v-if="merge.length > 0">
      <h1>Tabella per note Danea</h1>
      <table
        ref="tabelladaneanotedanea"
        class="table table-hover">
        <tbody>
          <tr>
            <th scope="col">Codice Alternativo</th>
            <th scope="col" style="text-align: left">Marchio</th>
          </tr>
          <tr v-for="(prod, index) in merge" :key="index">
            <td>&nbsp;{{ prod.codice }}</td>
            <td style="font-weight: bold">
              <a
                :href="
                  'https://cirar.it/catalogsearch/result/?q=' + prod.codice
                "
                >{{ prod.fornitore }}</a
              >
            </td>
          </tr>
        </tbody>
        <col width="225" />
        <col width="152" />
      </table>
    </div>
  </div>
</template>

<script>
import tables from "./tables.json";
import oe from "./oe.json";
import ac from "./ac.json";
import { BRow, BCol, BFormTextarea, BFormGroup, BButton } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    BRow,
    BCol,
    BFormTextarea,
    BFormGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      stringAC: "",
      stringOE: "",
      tabless: tables,
      acJSON: ac.am.map((el) => ({
        codice: el.art_codice,
        fornitore: el.sup_ragsoc,
      })),
      oeJSON: oe.oe.map((el) => ({
        codice: el.art_codice,
        fornitore: el.sup_ragsoc,
      })),
      merge: [],
    };
  },
  mounted() {
    localize("it");
    /* const augerObject = this.acJSON.filter(el => el.fornitore == 'AUGER').sort((a, b) => a.fornitore.localeCompare(b.fornitore))
    const notAuger = this.acJSON.filter(el => el.fornitore !== 'AUGER').sort((a, b) => a.fornitore.localeCompare(b.fornitore))
    this.merge = [...augerObject, ...notAuger, ...this.oeJSON] */

    /* let stringACJSON = JSON.parse(this.stringAC)
    let stringOEJSON = JSON.parse(this.stringOE)
    stringACJSON = stringACJSON.am.map(el => ({ codice: el.art_codice, fornitore: el.sup_ragsoc }))
    stringOEJSON = stringOEJSON.oe.map(el => ({ codice: el.art_codice, fornitore: el.sup_ragsoc }))
    const augerObject = stringACJSON.filter(el => el.fornitore == 'AUGER').sort((a, b) => a.fornitore.localeCompare(b.fornitore))
    const notAuger = stringOEJSON.JSONfilter(el => el.fornitore !== 'AUGER').sort((a, b) => a.fornitore.localeCompare(b.fornitore))
    this.merge = [...augerObject, ...notAuger, ...this.oeJSON]
 */
  },
  methods: {
    convertiInTabelle() {
      this.$refs.textboxrequired.validate().then((success) => {
        if (success) {
          let stringACJSON = JSON.parse(this.stringAC);
          let stringOEJSON =
            this.stringOE.length > 0 ? JSON.parse(this.stringOE) : "";
          // console.log(stringACJSON)
          stringACJSON = stringACJSON.am.map((el) => ({
            codice: el.art_codice,
            fornitore: el.sup_ragsoc,
          }));
          const augerObject = stringACJSON
            .filter((el) => el.fornitore == "AUGER")
            .sort((a, b) => a.fornitore.localeCompare(b.fornitore));
          const notAuger = stringACJSON
            .filter((el) => el.fornitore !== "AUGER")
            .sort((a, b) => a.fornitore.localeCompare(b.fornitore));
          this.merge = [...augerObject, ...notAuger];
          if (typeof stringOEJSON == "object") {
            stringOEJSON = stringOEJSON.oe.map((el) => ({
              codice: el.art_codice,
              fornitore: el.sup_ragsoc,
            }));
            this.merge = [...this.merge, ...stringOEJSON];
          }
        }
      });
    },
    copiaTabellaHTML() {
      const tabellaString = this.$refs.tabelladanea
      // console.log(tabellaString.outerHTML)
      // this.$copyText(tabellaString.outerHTML)
      navigator.clipboard.writeText(tabellaString.outerHTML)
    },
  },
};
</script>

<style>
</style>
